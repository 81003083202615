import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'


// TODO: M'interessa més la descripció i els keywords diferents en funció de l'idioma o tots iguals ???
const Layout = ({ children, location, lang }) => {

  let content;
  let desc = "Vida sentida explica l'experiència personal de conviure amb un trastorn obsessiu compulsiu. És una experiència vital que permet fer un procés d’autoconeixement immens, descobrir i desenvolupar el propi potencial i transformar el dolor en amor. Qualsevol persona pot retrobar la seva saviesa, siguin quines siguin les limitacions a superar, i recuperar l’equilibri intern per obrir el seu cor a la vida, prendre consciència de qui és i viure plenament.";  
  let keywords = "vida sentida, toc, ocd, Gemma Farràs, llibre, trastorn mental, autoconeixement, creixement personal, emocions, consciència"
  let ogdesc = "Vida sentida explica l'experiència personal de conviure amb un trastorn obsessiu compulsiu. És una experiència vital que permet fer un procés d’autoconeixement immens, descobrir i desenvolupar el propi potencial i transformar el dolor en amor. Qualsevol persona pot retrobar la seva saviesa, siguin quines siguin les limitacions a superar, i recuperar l’equilibri intern per obrir el seu cor a la vida, prendre consciència de qui és i viure plenament.";
  let title = "Vida sentida, gràcies a un trastorn mental";
  let url = "https://www.gemmafarras.cat"
  let image = "https://www.gemmafarras.cat/ogimage/ogimg-ca.jpg"

  if (!lang)
    lang="ca";

  if (lang === "es") {
    desc = "Vida sentida explica la experiencia personal de convivir con un trastorno obsesivo compulsivo. Es una experiencia vital que permite hacer un proceso de autoconocimiento inmenso, descubrir y desarrollar el propio potencial y transformar el dolor en amor. Toda persona puede reencontrar su sabiduría, sean cuales sean las limitaciones a superar, y recuperar el equilibrio interior para abrir su corazón a la vida, tomar conciencia de quien es y vivir plenamente."
    keywords = "vida sentida, toc, ocd, Gemma Farràs, libro, trastorno mental, autoconocimiento, crecimiento personal, emociones, consciencia"
    ogdesc = "Vida sentida explica la experiencia personal de convivir con un trastorno obsesivo compulsivo. Es una experiencia vital que permite hacer un proceso de autoconocimiento inmenso, descubrir y desarrollar el propio potencial y transformar el dolor en amor. Toda persona puede reencontrar su sabiduría, sean cuales sean las limitaciones a superar, y recuperar el equilibrio interior para abrir su corazón a la vida, tomar conciencia de quien es y vivir plenamente.";
    title = "Vida sentida, gracias a un trastorno mental";
    url = "https://www.gemmafarras.cat/es"
    image = "https://www.gemmafarras.cat/ogimage/ogimg-es.jpg"
  }

  if (location && (location.pathname === '/' || location.pathname === '/es/' )) {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
          >
            <html lang={lang} />
            <meta name='description' content={desc} />
            <meta name='keywords' content={keywords} />
            <meta name='google-site-verification' content='LPOdyWBZRR1f58gLnBKXicWqHbysMKQM2S5cgbdc1NU' />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={ogdesc} />
            <meta property="og:image" content={image} />
            <meta property="og:type" content="website" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
